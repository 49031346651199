<template>
    <div class="content-wrapper">
        <h1>Sitemap</h1>
        <ul>
            <li v-for="item in links" :key="item.title">
                <router-link
                    :to="item.url"
                    class="route-link">
                    {{item.title}}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                links: [
                    {
                        url: "/",
                        title: "Home"
                    },
                    {
                        url: "/how-it-works",
                        title: "How it works"
                    },
                    {
                        url: "/visuals",
                        title: "Visuals"
                    },
                    {
                        url: "/configurator",
                        title: "Configurator"
                    },
                    {
                        url: "/contact-us",
                        title: "Contact us"
                    },
                    {
                        url: "/faq",
                        title: "Faq"
                    },
                    {
                        url: "/privacy-policy",
                        title: "Privacy policy"
                    },
                    {
                        url: "/terms-of-use",
                        title: "Terms of use"
                    },
                    {
                        url: "/cookie-policy",
                        title: "Cookie policy"
                    },
                    {
                        url: "/blog",
                        title: "Blog"
                    },
                    {
                        url: "/login",
                        title: "Login"
                    },
                    {
                        url: "/registration",
                        title: "Registration"
                    },
                    {
                        url: "/sitemap",
                        title: "Sitemap"
                    }
                ]
            };
        }
    };
</script>

<style lang="scss" scoped>
.content-wrapper {
  width: 66.35%;
  margin: 0 auto 134px;
  padding-bottom: 91px;
  padding-top: 62px;
}

h1 {
  font-weight: 500;
  font-size: 56px;
  line-height: 100%;
  color: #464646;
  margin-bottom: 16px;
}
.route-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
